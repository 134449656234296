export function karteTracker() {
  if (
    process.env.NODE_ENV !== 'production' &&
    process.env.NODE_ENV !== 'staging'
  ) {
    return
  }

  function searchTrackerLoop(loopLimit, i) {
    if (i <= loopLimit) {
      setTimeout(() => {
        if (window.krt) {
          window.krt('send', 'view')
        } else {
          searchTrackerLoop(loopLimit, ++i)
        }
      }, 300)
    }
  }

  try {
    if (window.krt) {
      window.krt('send', 'view')
    } else {
      // 'window.krt'が確認できなければ300ms毎に再確認(max3回)
      searchTrackerLoop(3, 0)
    }
  } catch (e) {
    // エラー時も'window.krt'の再確認
    searchTrackerLoop(3, 0)
  }
}
