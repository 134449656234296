
import { Component, Prop, Vue } from 'vue-property-decorator'
import ArticleCard from '~/components/shared/card/ArticleCard.vue'

@Component({
  components: {
    ArticleCard
  }
})
export default class TopCarousel extends Vue {
  @Prop({
    type: Array,
    required: true
  })
  private items!: any[]

  @Prop({
    type: String,
    required: true
  })
  private type!: string

  private isArticle = this.type === 'article'
  private uid = Math.random().toString(36).substring(2, 10)

  private get carouselSetting() {
    const settings: any = {
      slidesPerView: this.isArticle ? 1.3 : 'auto',
      spaceBetween: 24,
      loop: true,
      centeredSlides: true,
      speed: 1000,
      touchRatio: 1,
      observeParents: true,
      observer: true,
      navigation: {
        prevEl: `.swiper-button-prev-${this.uid}`,
        nextEl: `.swiper-button-next-${this.uid}`
      },
      threshold: 10,
      grabCursor: true,
      simulateTouch: true,
      allowTouchMove: true,
      breakpoints: {
        767: {
          slidesPerView: 'auto',
          spaceBetween: this.isArticle ? 64 : 32
        }
      }
    }
    if (this.isArticle)
      settings.pagination = { el: `.swiper-pagination-${this.uid}` } // uniqueなidを指定
    return settings
  }
}
